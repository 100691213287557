import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Form, Input, notification, Spin, Tooltip} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import Cookies from "js-cookie";
import {getCarrier, updateCarrier} from "../services/carrier/carrier.service";


const CarrierPage: React.FC = () => {
    const carrierId = JSON.parse(Cookies.get("_auth_state") as string).carrier_id
    const [isLoading, setLoading] = useState(false);
    const [carrier, setCarrier] = useState({carrier_name: "", email: "", contacts: {financial: "", notifications: ""}})

    const retrieveCarrier = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getCarrier(carrierId);
            const { email, contacts, carrier_name } = response.data.carrier;
            setCarrier({
                email, contacts, carrier_name
            });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [carrierId]);

    useEffect(() => {
        retrieveCarrier();
    }, [retrieveCarrier]); // Empty dependency array ensures this runs only once on mount

    const handleChange = (e: any) => {
        const {name,value} = e.target
        setCarrier({...carrier, contacts: {...carrier.contacts, [name]: value}})
    }
    const handleSubmit = async () => {
        try{
            setLoading(true);
            await updateCarrier(Number(carrierId), carrier)
            notification.success({message: "Aggiornato con successo"})
        } catch(err) {
            notification.error({message: "Impossibile aggiornare le informazioni"})
        } finally {
            setLoading(false);
        }
    }
    return (
        <div style={{padding: "10px"}}>
            {isLoading ? <Spin/> :  <Card style={{width: '100%'}}>
                <div style={{width: '30%'}}>
                    <Form>
                        <div>
                            <h2>Principale</h2>
                            <Form.Item>
                                <Tooltip placement="top" title={`Campo non modificabile manualmente, contattare penali@sennder.it per variazioni`}>
                                    <label htmlFor="carrier_name">Azienda</label>
                                    <Input
                                        type="text"
                                        placeholder="Fornitore s.r.l"
                                        disabled
                                        size={"large"}
                                        value={carrier?.carrier_name}
                                        name="carrier_name"

                                    />
                                </Tooltip>

                            </Form.Item>
                            <Form.Item>
                                <Tooltip placement="top" title={`Campo non modificabile manualmente, contattare penali@sennder.it per variazioni`}>
                                    <label htmlFor="email">E-mail</label>
                                    <Input
                                        type="text"
                                        placeholder="fornitore@sennder.it"
                                        disabled
                                        size={"large"}
                                        value={carrier?.email}
                                    />
                                    <i>Email principale usata per il login</i>

                                </Tooltip>
                            </Form.Item>
                        </div>
                    </Form>
                    <Form>
                        <div>
                            <h2>Contatti</h2>
                            <Form.Item>
                                <Tooltip placement="top" title={`E-mail per l'invio di comunicazioni e notifiche sulle penali`}>
                                    <label htmlFor="email">Comunicazioni</label>
                                    <Input
                                        type="text"
                                        placeholder="fornitore@sennder.it"
                                        size={"large"}
                                        value={carrier?.contacts.notifications}
                                        onChange={handleChange}
                                        name="notifications"
                                    />
                                </Tooltip>
                            </Form.Item>



                            <Form.Item>
                                <Tooltip placement="top"
                                         title={`E-mail per l'invio di comunicazioni finanziarie (richieste di pagamento)`}>
                                    <label htmlFor="email">Contabilità</label>
                                    <Input
                                        type="text"
                                        placeholder="fornitore@sennder.it"
                                        size={"large"}
                                        value={carrier?.contacts.financial}
                                        onChange={handleChange}
                                        name="financial"
                                    />
                                </Tooltip>
                            </Form.Item>

                        </div>
                    </Form>

                </div>
                <Button icon={<CheckCircleOutlined />} type={"primary"} onClick={handleSubmit}>
                    Conferma
                </Button>
            </Card>}


        </div>
    );
};

export default CarrierPage;
