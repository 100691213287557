import React from "react";
import { FormattedDate } from "react-intl";
import { Popover, Space, Typography } from "antd";
import { IPenalty } from "../../services/penalty";

const { Text } = Typography;

const DelayCell = ({ data }: { data: IPenalty }): JSX.Element => {
  const { delay_minutes } = data;

  const hoverContent = (
    <Space direction={"vertical"}>
      <Text>
        <span className="mr-2">Partenza prevista: </span>
        {data.planned_departure_at ? (
          <FormattedDate
            value={data.planned_departure_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Partenza effettiva: </span>
        {data.actual_departure_at ? (
          <FormattedDate
            value={data.actual_departure_at.timestamp}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Arrivo previsto: </span>
        {data.planned_arrival_at ? (
          <FormattedDate
            value={data.planned_arrival_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Arrivo effettivo: </span>
        {data.actual_arrival_at ? (
          <FormattedDate
            value={data.actual_arrival_at.timestamp}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
    </Space>
  );
  return (
    <Popover content={hoverContent} destroyTooltipOnHide>
      {delay_minutes ? <Text>{delay_minutes} min</Text> : null}
      <br />
    </Popover>
  );
};

export default DelayCell;
