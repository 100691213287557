import axios from "axios";

interface ICredentials {
  email: string;
  password: string;
}

export async function authenticateCarrier(credentials: ICredentials) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/`, credentials);
}

export async function requestCarrierPasswordReset(email: string) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
    email,
  });
}
