import { Card, Col, Row } from "antd";
import "../assets/css/stats.css";
interface IProps {
  isLoading: boolean;
  data?: any;
  type: "submitted" | "pending";
}

const Stats = (props: IProps) => {
  const { data } = props;
  const title =
    props.type === "submitted"
      ? "N. Penali processate"
      : "N. Penali da giustificare";
  return (
    <div className="stats">
      <Row gutter={20} className={"mb-3"}>
        <Col span={8}>
          <Card title="Fornitore" size="small" style={{ borderRadius: "10px" }}>
            <h3>{data?.carrier}</h3>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={title} size="small" style={{ borderRadius: "10px" }}>
            <h3>{data?.penalties}</h3>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Ordini totali"
            size="small"
            style={{ borderRadius: "10px" }}
          >
            <h3>{data?.orders}</h3>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Stats;
