import { Layout } from "antd";
import "../assets/css/sennder-header.css";
import logo from "../assets/images/sennder-italia.png";
import AppMenu from "./AppMenu";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
function SennderHeader() {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/service/pending");
  };
  return (
    <Header className="sennder-header">
      <p></p>
      <div className="logo">
        <img
          src={logo}
          alt={"Sennder"}
          onClick={goToHome}
          style={{ cursor: "pointer" }}
        />
      </div>
      <AppMenu />
    </Header>
  );
}

export default SennderHeader;
