import React from "react";
import "../assets/css/info.css";
import { Tag } from "antd";

const InfoPage: React.FC = () => {
  return (
    <div className="info-wrapper">
      <h1>Procedura Penali</h1>
      <p>
        sennder Italia S.r.l. (c.f./p.i. 10933700964) con sede legale a 20125,
        Milano (MI) - Via Arbe 49, 20125, Milano, in persona del legale
        rappresentante pro tempore (di seguito “sennder”) e il vettore (di
        seguito il “Vettore” e congiuntamente le “Parti”) convengono che le
        seguenti disposizioni integrano e non sostituiscono i “ TERMINI &amp;
        CONDIZIONI GENERALI - VETTORI ” consultabili{" "}
        <a
          href="https://www.sennder.com/it/terms-conditions/carrier"
          target="_blank"
          rel="noreferrer"
        >
          qui
        </a>
        . In caso di trasporti eseguiti sul network avente come punto di origine
        e/o di destino un hub di Poste Italiane S.p.a o SDA Express Courier
        S.p.a. (di seguito il “Network Postale”) e qualora vi siano difformità
        tra gli orari presenti nell&#39;Ordine di Trasporto e le performance
        rese dal Vettore (con riferimento alla percorrenza per arco gestito)
        sennder avrà il diritto di addebitare al Vettore una penale, calcolata
        in base alla tabella penali, consultabile alla tabella penali,
        consultabile agli articoli 17 e 18 dei “ TERMINI &amp; CONDIZIONI
        GENERALI - VETTORI ”.
      </p>
      <h1>Manuale</h1>
      <p>
        Il Manuale per la Gestione dei Disservizi rinvenibile a{" "}
        <a
          href="https://drive.google.com/file/d/1C9arTeUqSAbKD_EtL0HDAD2fXuv4BLEB/view"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline" }}
        >
          questo link
        </a>{" "}
        serve come linea guida per poter sottomettere giustificativi idonei,
        migliorare la qualità dei documenti allegati e velocizzare il processo
        della gestione delle potenziali penali
      </p>
      <h1>Dati</h1>
      <p>
        Il Vettore è tenuto al rispetto delle istruzioni riguardanti il
        trasporto che pervengano per iscritto da sennder o dal destinatario, con
        particolare riferimento alle date di carico e scarico, alla compilazione
        dei documenti accompagnatori al trasporto (quali a titolo
        esemplificativo ma non esaustivo: la lettera di vettura), nonché
        all&#39;esecuzione dell&#39;Ordine di Trasporto. Il Vettore è tenuto
        inoltre al controllo della correttezza dei dati/orari rilasciati sul
        foglio di corsa e/o lettera di vettura e/o polizza di carico e/o
        documento di trasporto. Il Vettore sarà responsabile di qualsiasi danno
        e/o spesa derivante dall&#39;omessa e/o parziale e/o non corretta
        esecuzione di tali obblighi e dovrà indennizzare sennder a prima
        richiesta a seguito di qualsivoglia pretesa e/o sanzione rivolta o
        comminata nei confronti di sennder.
      </p>

      <h1>Legenda</h1>
      <p>
        <Tag color="#f57c00">Referenza</Tag> ID Identificativo sennder associato
        all'ordine. Cliccando sull'ordine è possibile aprire l'ordine
        corrispondente nella piattaforma Orcas.
      </p>

      <p>
        <Tag color="#f57c00">Arco</Tag> Parte della tratta o Hub a cui la penale
        è riferita.
      </p>

      <p>
        <Tag color="#f57c00">Ritardo</Tag> Minuti di ritardo sull'arco o ritardo
        di posizionamento sull'hub, Passando con il cursore sopra il dettaglio
        tratta saranno mostrate maggiori informazioni su orari e tratta completa
      </p>

      <p>
        <Tag color="#f57c00">Tipo di ritardo</Tag> Ritardo in percorrenza,
        posizionamento, partenza, No SHOW (servizio non effettuato).
      </p>

      <p>
        <Tag color="#f57c00">Scadenza</Tag> Data di scadenza
      </p>

      <p>
        <Tag color="#f57c00">Penale</Tag> Percentuale da decurtare dal prezzo
        totale dell'ordine
      </p>

      <p>
        <Tag color="#f57c00">Categoria</Tag> Casistica di ritardo.
      </p>

      <p>
        <Tag color="#f57c00">Giustificazione</Tag> Motivo del ritardo
      </p>

      <p>
        <Tag color="#f57c00">Allegato</Tag> Documentazione a supporto della
        giustificazione (fino a 5 allegati in formato PDF, Jpeg)
      </p>

      <h1>Tempistiche</h1>
      <p>
        sennder Italia S.r.l., nel caso in cui rilevi un ritardo di percorrenza
        e/o arrivo e/o partenza, riferito ad una delle linee comprese nel
        "Network Postale" procederà alla segnalazione della applicanda penale al
        vettore,
      </p>
      <ul>
        <li>
          nel caso in cui il vettore sia in grado di comprovare, tramite idonea
          documentazione (il cui elenco è consultabile all’interno del documento
          <a
            href="https://drive.google.com/file/d/1C9arTeUqSAbKD_EtL0HDAD2fXuv4BLEB/view"
            target="_blank"
            rel="noreferrer"
          >
            “Manuale per la gestione dei disservizi”
          </a>
          ), di trovarsi in una delle casistiche che prevedono la non
          applicazione della penale, quest'ultimo sarà tenuto a compilare gli
          appositi campi e ad inserire le ragioni del ritardo, procedendo a
          fornire il giustificativo stesso entro 7 (sette) giorni dalla
          segnalazione di sennder.
        </li>
      </ul>
      <p>Laddove:</p>
      <ul>
        <li>
          siano trascorsi 7 giorni (sette) dalla segnalazione senza che il
          giustificativo sia stato fornito (costituendo tale casistica
          un'accettazione a tutti gli effetti della penale da parte del
          vettore), ovvero;
        </li>
        <li>
          il giustificativo sia stato fornito entro il termine di 7 giorni
          (sette) dalla segnalazione, ma le osservazioni in esso contenute e
          formulate dal Vettore/Trazionista risultino mancanti, errate e/o
          infondate;
        </li>
      </ul>
      <p>
        sennder Italia S.r.l. procederà con l’effettiva applicazione della
        penale al Vettore/Trazionista ed al relativo addebito della stessa.
      </p>

      <h1>Richiesta di pagamento</h1>
      <p>
        sennder Italia S.r.l. per ciascun servizio di trasporto in relazione al
        quale è prevista l’applicazione di una penale, provvederà all'invio di
        una Richiesta preliminare di Pagamento (o RDP) contenente:
      </p>

      <ul>
        <li>le linee oggetto di disservizio;</li>
        <li>le date di esecuzione delle linee;</li>
        <li>l'ammontare relativo alla penale, calcolato come segue</li>
      </ul>

      <p>
        <b>Penali per ritardo di posizionamento, partenza e percorrenza</b>:
        l’importo della penale è calcolato come percentuale (%) sul totale del
        prezzo della linea così come definito dall’art. 18.2 dei Termini e
        Condizioni Generali;
      </p>

      <p>
        <b>
          Penali per non esecuzione del servizio di trasporto senza rispetto del
          preavviso di 24h rispetto al previsto orario di inizio:
        </b>
        l’importo della penale è calcolato come percentuale (%) sul totale del
        compenso pattuito per il trasporto così come definito dall’art. 17.5 dei
        Termini e Condizioni Generali;
      </p>

      <p>
        <b>
          Settimanalmente ricevete un riepilogo delle carenze dei dispositivi di
          sicurezza a voi assegnatiPenali per mancanza di dispositivi tecnici di
          sicurezza:
        </b>
        Settimanalmente ricevete un riepilogo delle carenze dei dispositivi di
        sicurezza a voi assegnati. L’importo della penale è calcolato come somma
        totale delle penali definite all’art. 6.10 dei Termini e Condizioni
        Generali, per mancanza di ciascun tipo di dispositivi tecnici di
        sicurezza così come previsti dall’art. 6.7 da lett. a) a lett. j)
      </p>

      <p>La Richiesta di Pagamento contiene:</p>
      <ul>
        <li>
          i dati anagrafici del Vettore quali
          <ul>
            <li>la ragione sociale</li>
            <li>la partita IVA o il codice fiscale</li>
            <li>la sede legale</li>
          </ul>
        </li>
        <li>l'indicazione del numero seriale di documento</li>
        <li>la data di emissione del documento.</li>
      </ul>
      <p>
        Gli importi indicati nelle Richieste di Pagamento non vengono indicati
        nel Riepilogo Ordini. Le somme dovute a titolo di penale non sono
        soggette ad applicazione di IVA ai sensi dell’art. 15 comma 1 n. 1)
        D.P.R. 633/1972.
      </p>
      <p>
        Le somme dovute a titolo di penale prevedono l'applicazione dell’imposta
        di bollo pari ad €2,00 euro per singolo documento, così come specificato
        dall’art. 15 comma 1 n. 1) D.P.R. 633/1972 e dalla R.M. Entrate n. 64
        del 23/04/2004.
      </p>
      <p>
        Le richieste di pagamento vengono sottratte e/o detratte e/o compensate
        al momento del pagamento.
      </p>
      <p>
        In caso di ulteriori domande, puoi contattarci all'indirizzo{" "}
        <a href="mailto:penali@sennder.it">penali@sennder.it</a>
      </p>
    </div>
  );
};

export default InfoPage;
