import { Button, Checkbox, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
const ModalTitle = (
  <>
    <InfoCircleOutlined style={{ marginRight: "8px", color: "#f57c00" }} />
    Benvenuto
  </>
);

const FirstAccessModal = (props: { onOk: any; open: any }) => {
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const [noShow, setNoShow] = useState(true);
  const handleConfirmChange = (e: any) => {
    setIsConfirmChecked(e.target.checked);
  };

  const handleNoShowChange = (e: any) => {
    setNoShow(e.target.checked);
  };

  return (
    <Modal
      title={ModalTitle}
      open={props.open}
      okButtonProps={{ disabled: !isConfirmChecked }}
      closable={false}
      footer={[
        <Button
          type="primary"
          disabled={!isConfirmChecked}
          onClick={() => props.onOk(noShow)}
        >
          OK
        </Button>,
      ]}
    >
      <p>
        Prima di utilizzare la dashboard, assicurati di aver letto le
        <a href="/info" target="_blank">
          {" "}
          informazioni e il manuale di utilizzo{" "}
        </a>
        per il corretto utilizzo della piattaforma
      </p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Checkbox onChange={handleConfirmChange}>
          Conferma presa visione
        </Checkbox>
        <Checkbox onChange={handleNoShowChange} checked={noShow}>
          Non mostrare più
        </Checkbox>
      </div>
    </Modal>
  );
};

export default FirstAccessModal;
