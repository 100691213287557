export interface IJustification {
  category?: PenaltyCategory;
  body?: string;
  attachments_url?: string[];
}

export interface IStats {
  numberOfPenalties: number;
  numberOfOrders: number;
  amount: number;
}

export interface IStatus {
  code: string;
  name: string;
  color: string;
}

export interface IPenaltyResult {
  _id: string;
  success: number;
  message: string;
  entity?: IPenalty;
  details: { message: string; column: string }[];
}

export interface IPenaltyFilter {
  status?: string[];
  tags?: string[];
  has_comment?: string;
  has_attachment?: string;
  gps_tracked?: string;
  week?: string;
  sennder_reference?: string;
  customer_reference?: string;
  carrier_name?: string;
}

export interface IPenaltyBase {
  _id: string;
  order_id?: string;
  sennder_reference?: string;
  customer_reference?: string;
  carrier_id?: string;
  carrier_name?: string;
  status?: string;
  line_code?: string;
  order_date?: string;
  week?: number;
  comments?: number;
  received_on?: string;
  delay_type?: string;
  gps_tracked?: boolean;
  created_at?: string;
  justified_at?: string;
  billed_at?: string;
  expiration_date?: string;
  competence?: string;
  delay_minutes?: number;
  route_composition?: string;
  route_km?: number;
  tour_composition?: string;
  tour_km?: number;
  penalty_percentage?: number;
  sda_penalty_cost?: number;
  total_net_price?: number;
  total_net_cost?: number;
  planned_departure_at?: string;
  integration_request_body?: string;
  processing_category?: string;
  processing_body?: string;
  actual_departure_at?: {
    timestamp?: string;
    source?: string;
  };
  planned_arrival_at?: string;
  actual_arrival_at?: {
    timestamp?: string;
    source?: string;
  };
  justification?: IJustification;
  message?: string;
  invoice_number?: Number;
}

export interface IPenalty extends IPenaltyBase {
  tags?: string[];
  thread?: {
    id?: string;
    subscribers?: string[];
  };
  penalty_percentage: number;
}

export interface IPenaltyError {
  column: string;
  message: string;
}

export interface IPenaltyImport extends IPenaltyBase {
  tags?: string;
  thread?: {
    id?: string;
    subscribers?: string;
  };
  errors?: IPenaltyError[];
}

export interface IPenaltyBeforeUpload extends IPenalty {
  attachmentsList?: any[];
}

export interface IJustifiedPenalty {
  _id: string;
  status: string;
  tags: string[];
  justification: {
    body?: string;
    category?: PenaltyCategory;
  };
}

export type PenaltyCategory =
  | "BROKEN_VEHICLE"
  | "WRONG_DOCUMENT"
  | "EMPTY_VEHICLE"
  | "TRAVEL_TIME"
  | "HUB_DELAY"
  | "TRAFFIC"
  | "FERRY_DELAY"
  | "VEHICLE_ACCIDENT"
  | "POLICE_STOP"
  | "HUB_OPENING"
  | "OTHER";

export enum PenaltyJustificationCategory {
  BROKEN_VEHICLE = "Guasto mezzo",
  WRONG_DOCUMENT = "Ritardo rilevato sul Documento di Viaggio non coerente",
  EMPTY_VEHICLE = "Mezzo vuoto",
  TRAVEL_TIME = "Tempi di percorrenza non rispettabili",
  HUB_DELAY = "Ritardo causato da Hub/Filiale/Cliente",
  TRAFFIC = "Traffico, cantieri stradali, condizioni metereologiche",
  FERRY_DELAY = "Ritardo traghetto",
  VEHICLE_ACCIDENT = "Incidente stradale al mezzo che esegue il servizio",
  POLICE_STOP = "Fermo Polizia",
  HUB_OPENING = "Orari aperture Hub/Filiali non compatibili con schedulati",
  OTHER = "Altro",
}
