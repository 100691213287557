import { InboxOutlined } from "@ant-design/icons";
import { message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useEffect, useState } from "react";

const AttachmentCell = (props: {
  data: any;
  onUpload: (value: any) => void;
  onRemove: (value: any) => void;
}): JSX.Element => {
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    setFileList([]);
    if (props.data.attachmentsList) {
      setFileList([...props.data.attachmentsList] as any);
    } else {
      setFileList([]);
    }
  }, [props.data.attachmentsList, props.data._id]);

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = (info: any) => {
    const { status, name, size } = info.file;

    const fileMb = Number((size / 1024 / 1024).toFixed(2));
    if (fileMb >= 5)
      return message.error(
        `${name} supera la dimensione massima concessa ${fileMb} MB / 5 MB`
      );

    setFileList(info.fileList);

    if (status === "done") {
      message.success(`${name} allegato alla penale.`);
      props.onUpload(info.fileList);
    } else if (status === "error") {
      message.error(`${name} non può essere caricato.`);
    }
  };

  const onFileRemove = (file: any) => {
    const updatedFileList = fileList.filter((f: any) => f.uid !== file.uid);
    setFileList(updatedFileList);
    props.onRemove(file.uid);
  };

  return (
    <Dragger
      key={props.data._id + "attachments-cell"}
      onChange={onChange}
      onRemove={onFileRemove}
      maxCount={5}
      customRequest={dummyRequest}
      multiple
      fileList={fileList}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Massimo 5 allegati (XLS, PDF, WORD, PNG, JPEG, JPG)
      </p>
      <p className="ant-upload-hint">
        I file verrano allegati alla penale nel momento in cui la si invia. Non
        sarà possibile modificarli dopo l'invio.
      </p>
    </Dragger>
  );
};

export default AttachmentCell;
