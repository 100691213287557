import { Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
const ModalTitle = (
  <>
    <CheckCircleOutlined style={{ marginRight: "8px", color: "green" }} />
    Ultimo step
  </>
);

const ConfirmPenalitesModal = (props: {
  onOk: any;
  onCancel: any;
  text: string;
  open: any;
}) => {
  return (
    <Modal
      title={ModalTitle}
      onOk={props.onOk}
      onCancel={props.onCancel}
      open={props.open}
    >
      <p>{props.text}</p>
    </Modal>
  );
};

export default ConfirmPenalitesModal;
