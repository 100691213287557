import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SennderHeader from "../components/SennderHeader";
import Sidebar from "../components/Sidebar";
import { Layout } from "antd";
import FirstAccessModal from "../components/modals/FirstAccessModal";

const { Content, Sider } = Layout;

interface IProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<IProps> = ({ children }) => {
  const [showAccessModal, setShowAccessModal] = useState(false);

  useEffect(() => {
    const firstAccess = localStorage.getItem("showFirstAccess");
    if (firstAccess == null || firstAccess === "true") setShowAccessModal(true);
  }, []);

  const handleModalOk = (noShow: boolean) => {
    setShowAccessModal(false);
    if (noShow) localStorage.setItem("showFirstAccess", "false");
    else localStorage.setItem("showFirstAccess", "true");
  };

  return (
    <Layout style={{ backgroundColor: "#fafafa" }} className="layout">
      <SennderHeader />
      <FirstAccessModal onOk={handleModalOk} open={showAccessModal} />
      <Content>
        <Layout
          style={{
            padding: "5px 0",
            backgroundColor: "#fafafa",
            height: "100%",
          }}
        >
          <Sider
            width={200}
            style={{ backgroundColor: "#fafafa", height: "100vh" }}
          >
            <Sidebar />
          </Sider>

          <Content style={{ minHeight: 280 }}>{children}</Content>
        </Layout>
      </Content>

      <Outlet />
    </Layout>
  );
};

export default AppLayout;
