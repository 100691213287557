import { Button, Card, Checkbox, Form, Input, Spin, notification } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { authenticateCarrier } from "../services/auth/auth.service";
import { Content } from "antd/es/layout/layout";
import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import "../assets/css/login.css";
import { useEffect, useState } from "react";

export default function LoginPage() {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/service/pending");
    }
  }, [isAuthenticated, navigate]);

  const storedEmail = localStorage.getItem("rememberMe");

  const onFinish = async (credentials: any) => {
    try {
      setIsLoading(true);
      const authResult = await authenticateCarrier(credentials);

      signIn({
        token: authResult.data.access_token,
        expiresIn: 43200,
        tokenType: "Bearer",
        authState: { ...authResult.data.carrier },
      });
      if (rememberMe) localStorage.setItem("rememberMe", credentials.email);
      notification.success({
        message: `Bentornato ${authResult.data.carrier.carrier_name}`,
      });
    } catch (error) {
      notification.error({ message: "Credenziali non valide" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Content className="content">
      <div className="login-content">
        <Card>
          <div className="text-center">
            <img src={logo} alt={"Sennder"} />
          </div>
          {isLoading && (
            <Spin
              spinning
              size="large"
              delay={200}
              style={{ alignSelf: "center" }}
            ></Spin>
          )}
          <Form
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            initialValues={{
              remember: storedEmail !== null,
              email: storedEmail || "",
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <a href="login/recover-password">Recupera Password</a>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox onClick={() => setRememberMe(!rememberMe)}>
                Ricordami
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <b>Log In</b>
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Content>
  );
}
