import {
  IJustifiedPenalty,
  IPenalty,
  IPenaltyBeforeUpload,
} from "../services/penalty";
import moment from "moment-timezone";

function getPenaltyPayload(penalty: IPenaltyBeforeUpload) {
  const { _id, tags, justification, attachmentsList } = penalty;

  if (
    justification?.body?.length &&
    justification?.category?.length &&
    attachmentsList?.length
  )
    return {
      _id,
      status: "TO_REVIEW",
      tags: tags && tags?.length ? [...tags, "Justified"] : ["Justified"],
      justification,
    };
  else return null;
}

function getPenaltiesPayload(
  penalties: Array<IPenaltyBeforeUpload>
): IPenalty[] {
  const finalData = penalties.reduce(
    (acc: Array<IJustifiedPenalty>, penalty) => {
      const payload: any = getPenaltyPayload(penalty);
      if (payload) acc.push(payload);

      return acc;
    },
    []
  );

  return finalData as IPenalty[];
}

function getPenaltyError(penalty: IPenaltyBeforeUpload) {
  const title = `${penalty.sennder_reference} (${penalty.route_composition})`;
  let rowErrors: string[] = [];

  if (!penalty.justification?.body?.length) {
    rowErrors.push("Giustificazione");
  }
  if (!penalty.justification?.category?.length) {
    rowErrors.push("Categoria");
  }
  if (!penalty.attachmentsList?.length) {
    rowErrors.push("Allegato");
  }

  return { title, errors: rowErrors, _id: penalty._id };
}

const filterObject = (obj: Record<string, any>): Record<string, any> => {
  const filteredObject: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      filteredObject[key] = obj[key];
    }
  });

  return filteredObject;
};

function getPenaltiesErrors(penalties: Array<IPenaltyBeforeUpload>) {
  return penalties
    .filter(
      (penalty) =>
        penalty.justification?.body?.length ||
        penalty.justification?.category?.length ||
        penalty.attachmentsList?.length
    )
    .map((penalty) => getPenaltyError(penalty))
    .filter((errorObj) => errorObj.errors.length > 0); // To exclude entries with no errors.
}

function mapStatus(status: any) {
  switch (status?.toLowerCase()) {
    case "applied":
      return "Penale Applicata";
    case "not_applied":
      return "Penale Non Applicata";
    case "billed":
      return "Fatturata";
    case "to_review":
      return "Giustificazione Inviata";
    default:
      return "Sconosciuto";
  }
}

function formatDate(date?: string, timestamp?: boolean) {
  if (!date) return null;

  const cetDate = moment.tz(date, "Europe/Berlin");
  const formattedDate = timestamp
    ? cetDate.format("DD/MM/YYYY HH:mm")
    : cetDate.format("DD/MM/YYYY");
  return formattedDate;
}

const mapType = (type: string) => {
  switch (type.toLowerCase().trim()) {
    case "no show":
      return "Non eseguito";
    case "transit":
      return "Percorrenza";
    case "departure":
      return "Partenza";
    case "positioning":
      return "Posizionamento";
    default:
      return type;
  }
};

export {
  getPenaltiesPayload,
  mapStatus,
  formatDate,
  getPenaltiesErrors,
  getPenaltyError,
  getPenaltyPayload,
  mapType,
  filterObject,
};
