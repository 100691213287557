import { Button, Card, Form, Input, Spin, notification } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { requestCarrierPasswordReset } from "../services/auth/auth.service";
import { Content } from "antd/es/layout/layout";
import { useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import "../assets/css/login.css";
import { useEffect, useState } from "react";
import { checkResetCode } from "../services/carrier/carrier.service";
import NewPasswordForm from "../components/NewPasswordForm";

export default function RecoverPasswordPage() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [emailInput, setEmailInput] = useState("");

  const [resetToken, setResetToken] = useState(null);
  const [resetCode, setResetCode] = useState(null);
  const [canReset, setCanReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/service/pending");
    }
  }, [isAuthenticated, navigate]);

  const handleEmailInputChange = (event: any) => {
    setEmailInput(event.target.value);
  };

  const sendCode = async () => {
    try {
      setIsLoading(true);
      if (!emailInput.length)
        return notification.error({ message: "Email non inserita" });
      const resetResponse = await requestCarrierPasswordReset(emailInput);
      setResetToken(resetResponse.data.access_token);
      notification.success({
        message: `Codice inviato via email a ${emailInput}`,
      });
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (credentials: any) => {
    try {
      setIsLoading(true);
      if (!resetToken || !credentials.code)
        return notification.error({ message: "Errore con la richiesta" });
      setResetCode(credentials.code);
      await checkResetCode({ code: credentials.code, token: resetToken });
      setCanReset(true);
    } catch (error) {
      notification.error({ message: "Codice non valido" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Content className="content">
      <div className="login-content">
        <Card>
          <div className="text-center">
            <img src={logo} alt={"Sennder"} />
          </div>
          {canReset ? (
            <NewPasswordForm token={resetToken} code={resetCode} />
          ) : (
            <Form
              name="normal_login"
              className="login-form"
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <h3>Recupera la tua password</h3>

              {isLoading && (
                <Form.Item>
                  <Spin
                    spinning
                    size="large"
                    delay={200}
                    style={{ alignSelf: "center" }}
                  ></Spin>
                </Form.Item>
              )}

              <Form.Item label="Email" name="email">
                <Input
                  onChange={handleEmailInputChange}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
                <Button onClick={sendCode}>Send Code</Button>
              </Form.Item>
              <Form.Item label="Code" name="code">
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="number"
                  placeholder="6 Digits code"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  <b>Continua</b>
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    </Content>
  );
}
