import { Button, Modal } from "antd";
import { AlertOutlined } from "@ant-design/icons";
const ModalTitle = (
  <>
    <AlertOutlined style={{ marginRight: "8px", color: "red" }} />
    Errore
  </>
);

const PenaltiesError = (penaltiesErrors: any) => {
  return penaltiesErrors.map((penaltyError: any, idx: number) => (
    <div key={idx}>
      <strong>{penaltyError.title}</strong>
      <ul>
        {penaltyError.errors.map((error: any, eIdx: any) => (
          <li key={eIdx}>
            {error} <b>Mancante</b>
          </li>
        ))}
      </ul>
    </div>
  ));
};
const PenaltiesErrorModal = (props: {
  onOk: any;
  onJustify: any;
  open: any;
  errors: any[];
}) => {
  return (
    <Modal
      title={ModalTitle}
      onOk={props.onOk}
      open={props.open}
      footer={[
        <Button key="submit" type="primary" onClick={props.onJustify}>
          Invia corrette
        </Button>,
        <Button key="submit" onClick={props.onOk}>
          Ok
        </Button>,
      ]}
    >
      <p>Le seguenti penali sono state compilate parzialmente:</p>
      {PenaltiesError(props.errors)}
      <i>
        Compila i dati mancanti prima di procedere o invia le penali
        giustificate correttamente tramite il bottone <b>'Invia corrette'</b>
      </i>
    </Modal>
  );
};

export default PenaltiesErrorModal;
