import { useEffect, useRef, useState } from "react";
import "../App.css";
import { FloatButton, Tooltip, notification } from "antd";
import { SendOutlined } from "@ant-design/icons";
import {
  IPenalty,
  IPenaltyBeforeUpload,
  PenaltyCategory,
  getPending,
  updatePenalty,
} from "../services/penalty";
import { unionBy } from "lodash";
import {
  getPenaltiesErrors,
  getPenaltyError,
  getPenaltyPayload,
} from "../common/Utils";
import PendingPenaltiesTable from "../components/PendingPenaltiesTable";
import Stats from "../components/Stats";
import Cookies from "js-cookie";
import ConfirmPenalitesModal from "../components/modals/ConfirmPenaltiesModal";
import PenaltiesErrorModal from "../components/modals/PenaltiesErrorModal";
import AttachmentsModal from "../components/modals/AttachmentsModal";
import { PenaltyFilters } from "../common/interfaces";
import FiltersDrawer from "../components/FiltersDrawer";

function PendingPenalties() {
  const carrier = JSON.parse(Cookies.get("_auth_state") as string);
  const stats = useRef({
    carrier: carrier.carrier_name,
    penalties: 0,
    orders: 0,
  });

  const [penalties, setPenalties] = useState<IPenaltyBeforeUpload[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSingleConfirmModalOpen, setIsSingleConfirmModalOpen] =
    useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [penaltiesErrors, setPenaltiesErrors] = useState<any[]>([]);
  const [singlePenaltyToUpload, setSinglePenaltyToUpload] =
    useState<IPenalty | null>(null);
  const [attachmentsModalPenalty, setAttachmentsModalPenalty] =
    useState<IPenalty | null>(null);

  const [filters, setFilters] = useState<PenaltyFilters>({});
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

  const handleAttachmentsModalOk = () => {
    setAttachmentsModalPenalty(null);
    setIsAttachmentsModalOpen(false);
  };

  const handleAttachmentsModalCancel = () => {
    setAttachmentsModalPenalty(null);
    setIsAttachmentsModalOpen(false);
  };

  const handleConfirmButton = () => {
    const errors = getPenaltiesErrors(penalties);
    setPenaltiesErrors(errors);
    if (errors.length) setIsErrorModalOpen(true);
    else setIsConfirmModalOpen(true);
  };

  const handleSingleConfirmModalOk = () => {
    setIsSingleConfirmModalOpen(false);
    if (singlePenaltyToUpload)
      confirmPenalty(singlePenaltyToUpload as IPenalty);
    setSinglePenaltyToUpload(null);
  };

  const handleSingleConfirmModalCancel = () => {
    setIsSingleConfirmModalOpen(false);
    setSinglePenaltyToUpload(null);
  };

  const handleErrorModalOk = () => {
    setPenaltiesErrors([]);
    setIsErrorModalOpen(false);
  };

  const handleErrorModalJustify = async () => {
    let errorIds = penaltiesErrors.map((e) => e._id);
    const submittablePenalties = penalties.filter(
      (penalty) => !errorIds.includes(penalty._id)
    );
    setIsErrorModalOpen(false);
    await confirmPenalties(submittablePenalties);
  };

  const handleConfirmModalOk = () => {
    setIsConfirmModalOpen(false);
    confirmPenalties();
  };

  const handleConfirmModalCancel = () => {
    setIsConfirmModalOpen(false);
  };

  async function confirmPenalties(choosenPenalties?: IPenaltyBeforeUpload[]) {
    const penaltiesToUpload =
      choosenPenalties ||
      penalties.filter(
        (p) =>
          p.justification?.body?.length &&
          p.justification?.category?.length &&
          p.attachmentsList
      );
    for (let penalty of penaltiesToUpload) {
      await confirmPenalty(penalty);
    }
  }

  async function confirmPenalty(updatedPenalty: IPenaltyBeforeUpload) {
    const payload: any = getPenaltyPayload(updatedPenalty);
    if (payload) {
      setLoading(true);

      try {
        await updatePenalty(
          updatedPenalty._id,
          payload,
          updatedPenalty.attachmentsList
        );

        notification.success({
          message: `${updatedPenalty.sennder_reference} (${updatedPenalty.tour_composition})  - Penale aggiornata`,
        });

        setPenalties((prevPenalties) => {
          let remainingPenalties = prevPenalties.filter(
            (penalty: IPenalty) => penalty._id !== updatedPenalty._id
          );
          stats.current.penalties = remainingPenalties.length;
          stats.current.orders = unionBy(
            remainingPenalties,
            "sennder_reference"
          ).length;

          return remainingPenalties;
        });
      } catch (error: any) {
        notification.error({
          message: `Impossibile giustificare ${updatedPenalty.sennder_reference} (${updatedPenalty.tour_composition})`,
        });
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleFiltersChange(filters: any) {
    setIsFilterSidebarOpen(false);
    setFilters(filters);
    setAttachmentsModalPenalty(null);
    setSinglePenaltyToUpload(null);
    setPenaltiesErrors([]);
    await retrivePenalties(filters);
  }

  function handleFilterRemove(filter: keyof PenaltyFilters) {
    const { [filter]: _, ...newFilters } = filters;
    handleFiltersChange(newFilters);
  }

  async function retrivePenalties(queryFilters: PenaltyFilters) {
    try {
      setLoading(true);
      const penaltiesResponse = await getPending(queryFilters);
      const data = penaltiesResponse.data;
      setPenalties(data);

      stats.current.penalties = data.length;
      stats.current.orders = unionBy(data, "sennder_reference").length;
    } catch (error: any) {
      notification.error({ message: "Errore", description: error.message });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    retrivePenalties({});
  }, []);

  function handleJustificationChange(id: string, justification: string) {
    // Find the penalty to update
    const updatedPenalties = [...penalties].map(
      (penalty: IPenaltyBeforeUpload) => {
        if (penalty._id === id) {
          return {
            ...penalty,
            justification: { ...penalty.justification, body: justification },
          };
        }
        return penalty;
      }
    );

    setPenalties(updatedPenalties);
  }

  function handleCategoryChange(id: string, category: PenaltyCategory) {
    // Find the penalty to update
    const updatedPenalties = [...penalties].map(
      (penalty: IPenaltyBeforeUpload) => {
        if (penalty._id === id) {
          return {
            ...penalty,
            justification: { ...penalty.justification, category },
          };
        }
        return penalty;
      }
    );

    setPenalties(updatedPenalties);
  }

  function handleSingleJustifyClick(id: string) {
    const penalty = penalties.find((penalty) => penalty._id === id) as any;
    setSinglePenaltyToUpload(penalty);
    if (singlePenaltyToUpload) {
      const penaltyError = getPenaltyError(penalty);
      if (penaltyError.errors.length) {
        setPenaltiesErrors([penaltyError]);
        setIsErrorModalOpen(true);
      } else setIsSingleConfirmModalOpen(true);
    }
  }

  function removeAttachmentsDuplicate(attachments: any) {
    return attachments.reduce((acc: any, current: any) => {
      const existingItem = acc.find((item: any) => item.uid === current.uid);

      if (!existingItem) {
        return [...acc, current];
      } else {
        if (current.status === "done") {
          return acc.map((item: any) =>
            item.uid === current.uid ? current : item
          );
        }
        return acc;
      }
    }, []);
  }

  function handleFileUpload(id: string, fileList: any[]) {
    const updatedPenalties = [...penalties].map(
      (penalty: IPenaltyBeforeUpload) => {
        if (penalty._id === id) {
          const attachments = penalty.attachmentsList
            ? [...penalty.attachmentsList, ...fileList]
            : [...fileList];

          const result = {
            ...penalty,
            attachmentsList: removeAttachmentsDuplicate(attachments),
          };
          return result;
        }

        return penalty;
      }
    );

    setPenalties(updatedPenalties);
  }

  function handleFileRemove(id: string, fileUid: any) {
    const updatedPenalties = [...penalties].map(
      (penalty: IPenaltyBeforeUpload) => {
        if (penalty._id === id) {
          const result = {
            ...penalty,
            attachmentsList: penalty.attachmentsList?.filter(
              (item: any) => item.uid !== fileUid
            ),
          };

          return result;
        }
        return penalty;
      }
    );

    setPenalties(updatedPenalties);
  }

  const handleAttachmentsButtonClick = (id: string) => {
    const penalty = penalties.find((penalty) => penalty._id === id)!;
    setAttachmentsModalPenalty(penalty);
    setIsAttachmentsModalOpen(true);
  };
  return (
    <div className="content-wrapper">
      <FiltersDrawer
        filters={filters}
        onClose={() => setIsFilterSidebarOpen(false)}
        open={isFilterSidebarOpen}
        onFilterConfirm={handleFiltersChange}
        availableFilters={["line_code", "expiration_date", "order_date"]}
      />

      <ConfirmPenalitesModal
        open={isConfirmModalOpen}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
        text="Vuoi confermare l'invio delle penali giustificate?"
      />
      <ConfirmPenalitesModal
        open={isSingleConfirmModalOpen}
        onOk={handleSingleConfirmModalOk}
        onCancel={handleSingleConfirmModalCancel}
        text="Vuoi confermare l'invio della penale?"
      />
      <PenaltiesErrorModal
        onJustify={handleErrorModalJustify}
        open={isErrorModalOpen}
        onOk={handleErrorModalOk}
        errors={penaltiesErrors}
      />
      <AttachmentsModal
        open={isAttachmentsModalOpen}
        onOk={handleAttachmentsModalOk}
        onCancel={handleAttachmentsModalCancel}
        onFileRemove={handleFileRemove}
        onFileUpload={handleFileUpload}
        data={attachmentsModalPenalty as IPenalty}
      />
      <Tooltip placement="top" title="Invia insieme tutte le penali compilate">
        <FloatButton
          icon={<SendOutlined />}
          type="primary"
          target="_blank"
          style={{ right: 24, width: 150 }}
          onClick={handleConfirmButton}
          description="Conferma massiva"
          shape="square"
        />
      </Tooltip>
      <Stats isLoading={loading} data={stats.current} type="pending" />
      <PendingPenaltiesTable
        loading={loading}
        data={penalties}
        onJustificationChange={handleJustificationChange}
        onCategoryChange={handleCategoryChange}
        onJustifyClick={handleSingleJustifyClick}
        onFileUpload={handleFileUpload}
        onFileRemove={handleFileRemove}
        onUploadButtonClick={handleAttachmentsButtonClick}
        onFilterClick={() => setIsFilterSidebarOpen(true)}
        filters={filters}
        onFilterRemove={handleFilterRemove}
      />
    </div>
  );
}

export default PendingPenalties;
