import axios from "axios";
import Cookies from "js-cookie";

export const checkResetCode = async (payload: any) => {
  const { code, token } = payload;
  return axios.post(
    `${process.env.REACT_APP_API_URL}/carriers/check-reset-code/`,
    {
      code,
    },
    {
      headers: {
        authorization: token,
      },
    }
  );
};

export const resetCarrierPassword = async (payload: any) => {
  const { code, token, password } = payload;
  return axios.post(
    `${process.env.REACT_APP_API_URL}/carriers/reset-password/`,
    {
      code,
      password,
    },
    {
      headers: {
        authorization: token,
      },
    }
  );
};



export const updateCarrier = async (carrierId: number, payload: any) => {
    return axios.put(
        `${process.env.REACT_APP_API_URL}/carriers/${carrierId}`,
        payload,
        {
            headers: {
                authorization: Cookies.get("_auth"),
            },
        }
    );
};



export const getCarrier = async (carrierId: number) => {
  return axios.get(
      `${process.env.REACT_APP_API_URL}/carriers/${carrierId}`,
      {
        headers: {
          authorization: Cookies.get("_auth"),
        },
      }
  );
};
