import {
  PenaltyCategory,
  PenaltyJustificationCategory,
} from "../services/penalty";

const getRequiredDocuments = (category: PenaltyCategory | null) => {
  switch (category) {
    case "EMPTY_VEHICLE":
      return ["Foglio di corsa attestante 'Mezzo vuoto' (o 'Parte vuoto')"];
    case "FERRY_DELAY":
      return [
        "Email a operativo sennder",
        "Comunicazione ufficiale dell'armatore",
        "GPS del mezzo",
      ];
    case "HUB_DELAY":
      return [
        "GPS del mezzo dal quale si possa evincere l'orario di attesa in Hub/filiale",
        "La segnalazione all'interno del foglio di corsa del ritardo causato dall'Hub e Filiale",
        "Email a operativo sennder",
      ];
    case "HUB_OPENING":
      return ["Email a operativo sennder", "GPS del mezzo"];
    case "POLICE_STOP":
      return [
        "Email a operativo sennder",
        "GPS del mezzo",
        "Verbale di polizia del fermo",
      ];
    case "TRAFFIC":
      return [
        "Evidenza traffico da fonti affidabili",
        "GPS del mezzo",
        "Prova di traffico tangibile (es. foto del traffico)",
        "Email a operativo sennder",
      ];
    case "TRAVEL_TIME":
      return [
        "Tracciato GPS del mezzo durante il percorso",
        "Storicità dei transiti sulla linea delle 3 settimana precedenti",
        "Email a operativo sennder",
      ];
    case "VEHICLE_ACCIDENT":
      return [
        "Email a operativo sennder",
        "GPS del mezzo",
        "Verbale di polizia dell'incidente",
      ];
    case "BROKEN_VEHICLE":
      return [
        "Email a operativo sennder",
        "GPS del mezzo",
        "Verbale dell'officina",
      ];
    case "WRONG_DOCUMENT":
      return ["GPS del mezzo", "Segnalazione all'interno del foglio di corsa"];

    case "OTHER":
      return [];
    default:
      return [];
  }
};

const RequiredDocuments = (props: {
  category: PenaltyCategory | null;
}): JSX.Element => {
  let requiredDocs = getRequiredDocuments(props.category);
  return (
    <>
      {props.category && requiredDocs.length ? (
        <>
          <p>
            Documenti richiesti per:
            <br />
            <b>{PenaltyJustificationCategory[props.category]}</b>
          </p>
          <ul>
            {requiredDocs.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default RequiredDocuments;
