function SafetyPenaltiesPage() {
  return (
    <div className="content-wrapper">
      <h1>Sviluppo in corso</h1>
      <p>Sezione non ancora disponibile</p>
    </div>
  );
}

export default SafetyPenaltiesPage;
