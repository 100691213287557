import { Button, Form, Input, Spin, notification } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetCarrierPassword } from "../services/carrier/carrier.service";
import { UserOutlined } from "@ant-design/icons";

export default function NewPasswordForm({ token, code }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (credentials: any) => {
    try {
      setIsLoading(true);
      if (credentials.new_password !== credentials.confirm_password)
        return notification.error({
          message: "Le due password non coincidono",
        });

      await resetCarrierPassword({
        token,
        password: credentials.new_password,
        code,
      });
      notification.success({
        message: "Password Cambiata",
      });
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      notification.error({ message: "Impossibile resettare la password" });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Form
      name="normal_login"
      className="login-form"
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
    >
      <h3>Imposta la nuova password</h3>
      {isLoading && (
        <Spin
          spinning
          size="large"
          delay={200}
          style={{ alignSelf: "center" }}
        ></Spin>
      )}
      <Form.Item
        label="Nuova Password"
        name="new_password"
        rules={[{ required: true, message: "Please insert the new password" }]}
      >
        <Input
          type="password"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="*****"
        />
      </Form.Item>
      <Form.Item
        label="Conferma Password"
        name="confirm_password"
        rules={[{ required: true, message: "Please confirm the new password" }]}
      >
        <Input
          type="password"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="*****"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          <b>Continua</b>
        </Button>
      </Form.Item>
    </Form>
  );
}
