import React from "react";
import { Tooltip, Typography } from "antd";
import { IPenalty } from "../../services/penalty";
import { formatDate } from "../../common/Utils";

const { Title, Text } = Typography;

const ReferenceCell = (props: IPenalty): JSX.Element => {
  const { sennder_reference, line_code, order_date } = props;
  const url = `https://app.orcas.sennder.com/orders?reference_number=${sennder_reference}&category=PAST`;
  return (
    <>
      <Title level={5} style={{ marginBottom: "0px" }}>
        <Tooltip placement="top" title="Apri su orcas">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#f57c00", fontSize: "13px" }}
          >
            {sennder_reference}
          </a>
        </Tooltip>
      </Title>
      <Text type={"secondary"} style={{ fontSize: "12px" }}>
        {line_code} | {formatDate(order_date)}
      </Text>
    </>
  );
};

export default ReferenceCell;
