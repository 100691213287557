import { Tag } from "antd";
import { PenaltyFilterLabel, PenaltyFilters } from "../common/interfaces";
import { formatDate, mapStatus } from "../common/Utils";

const FiltersRow = (props: { filters: any; onFilterRemove: any }) => {
  const handleFilterRemoval = (
    e: React.MouseEvent<HTMLElement>,
    key: keyof PenaltyFilters
  ) => {
    e.preventDefault();
    props.onFilterRemove(key);
  };

  const filtersTags = Object.keys(props.filters).map((key: any) => {
    const typedKey = key as keyof PenaltyFilters; // Type assertion
    const rawValue = props.filters[key];
    let value;
    if (rawValue.start && rawValue.end) {
      value = `${formatDate(rawValue.start)} - ${formatDate(rawValue.end)}`;
    } else if (key === "status") value = mapStatus(rawValue);
    else value = props.filters[key];

    return (
      <Tag closeIcon onClose={(e) => handleFilterRemoval(e, key)}>
        {PenaltyFilterLabel[typedKey]}: {value}
      </Tag>
    );
  });
  return <div>{filtersTags}</div>;
};

export default FiltersRow;
