import { Button } from "antd";
import { IPenalty } from "../../services/penalty";
import { SendOutlined } from "@ant-design/icons";
const ActionCell = (props: {
  data: IPenalty | any;
  onClick: () => void;
}): JSX.Element => {
  function handleButtonClick() {
    props.onClick();
  }

  return (
    <>
      <Button
        type="primary"
        icon={<SendOutlined />}
        onClick={handleButtonClick}
      />
    </>
  );
};

export default ActionCell;
