import React from "react";
import {
  IPenalty,
  PenaltyCategory,
  PenaltyJustificationCategory,
} from "../services/penalty";
import { ReferenceCell, DelayCell } from "../components/PenaltiesTable";
import Table, { ColumnsType } from "antd/es/table";
import { Button, Card, Tag, Tooltip } from "antd";
import { mapStatus, mapType } from "../common/Utils";
import { Input } from "antd";
import {
  UploadOutlined,
  CheckCircleFilled,
  WarningFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  SyncOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { FilterOutlined, DownloadOutlined } from "@ant-design/icons";
import FiltersRow from "./FiltersRow";
import { PenaltyFilters } from "../common/interfaces";

const { TextArea } = Input;
export interface SubmittedPenalty extends IPenalty {
  attachmentsList: any[];
}

function getStatusTag(status: string) {
  const statusText = mapStatus(status);
  let color = "green";
  let icon;
  switch (status.toLowerCase()) {
    case "applied":
      color = "red";
      icon = <ExclamationCircleOutlined />;
      break;
    case "not_applied":
      color = "green";
      icon = <CheckCircleOutlined />;
      break;
    case "billed":
      color = "orange";
      icon = <FileTextOutlined />;
      break;
    case "to_review":
      color = "yellow";
      icon = <SyncOutlined spin />;
      break;
    default:
      color = "grey";
      icon = <MinusCircleOutlined />;
      break;
  }
  return (
    <Tag color={color} icon={icon}>
      {statusText}
    </Tag>
  );
}

const SubmittedPenaltiesTable = (props: {
  data: any[];
  loading: boolean;
  onUploadButtonClick: any;
  download: any;
  onFilterClick: any;
  filters: any;
  onFilterRemove: any;
}): JSX.Element => {
  let attachmentsError = props.data.filter(
    (p) => !p.justification?.attachments_url?.length && p.status === "TO_REVIEW"
  ).length;
  const handleFilterRemove = (filter: keyof PenaltyFilters) => {
    props.onFilterRemove(filter);
  };
  const failedAttachmentsTitle = () => {
    if (attachmentsError)
      return (
        <Tooltip
          placement="top"
          title={`${attachmentsError} penali giustificate senza allegato`}
        >
          Allegati <WarningFilled style={{ color: "red" }} />
        </Tooltip>
      );
    return <>Allegati</>;
  };
  const columns: ColumnsType<SubmittedPenalty> = [
    {
      title: "Referenza",
      key: "sennder_reference",
      render: ReferenceCell,
      showSorterTooltip: false,
      width: 160,
      fixed: "left",
      sorter: (a, b) => {
        const value1 = a.sennder_reference || "";
        const value2 = b.sennder_reference || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Arco",
      render: (data: IPenalty) => <p>{data.route_composition}</p>,
      key: "tour_composition",
      width: 100,
      sorter: (a, b) => {
        const value1 = a.route_composition || "";
        const value2 = b.route_composition || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Ritardo",
      render: (data: IPenalty) => {
        return <DelayCell data={data} />;
      },
      key: "delay_minutes",
      width: 100,

      sorter: (a, b) => {
        const value1 = a.delay_minutes || 0;
        const value2 = b.delay_minutes || 0;
        return value1 - value2;
      },
    },
    {
      title: "Tipo",
      key: "delay_type",
      render: (data: IPenalty) => <p>{mapType(data.delay_type || "-")}</p>,
      width: 100,
      sorter: (a, b) => {
        const value1 = a.delay_type || "";
        const value2 = b.delay_type || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Penale",
      render: (data: IPenalty) => {
        return <p>{data.penalty_percentage * 100}%</p>;
      },
      key: "penalty_percentage",
      width: 80,
      sorter: (a, b) => {
        const value1 = a.penalty_percentage || 0;
        const value2 = b.penalty_percentage || 0;
        return value1 - value2;
      },
    },
    {
      title: "Categoria",
      key: "category",
      render: (data: IPenalty) => {
        const rawCategory = data.justification?.category as PenaltyCategory;
        const labelCategory = data.justification?.category
          ? PenaltyJustificationCategory[rawCategory]
          : "-";
        return <p>{labelCategory}</p>;
      },
      width: 250,
      sorter: (a, b) => {
        const value1 = a.justification?.category || "";
        const value2 = b.justification?.category || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Giustificazione",
      key: "justification",
      render: (data: IPenalty) => {
        return <TextArea value={data.justification?.body || "-"} disabled />;
      },
      width: 300,
      sorter: (a, b) => {
        const value1 = a.justification?.body || "";
        const value2 = b.justification?.body || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: failedAttachmentsTitle,
      key: "attachment",
      render: (data: SubmittedPenalty) => {
        if (
          !data.justification?.attachments_url?.length &&
          data.status === "TO_REVIEW"
        )
          return (
            <Button
              onClick={() => props.onUploadButtonClick(data._id)}
              icon={<UploadOutlined />}
            >
              {data.attachmentsList?.length || 0} Caricati
            </Button>
          );

        return (
          <>
            <CheckCircleFilled style={{ color: "green" }} />{" "}
            {data.justification?.attachments_url?.length || 0} Allegati
          </>
        );
      },
      width: 150, // Increase this value
    },
    {
      title: "Stato",
      key: "status",
      render: (data: IPenalty) => {
        return getStatusTag(data?.status || "");
      },
      width: 200,
      sorter: (a, b) => {
        const value1 = a.status || "";
        const value2 = b.status || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Motivo Rifiuto",
      key: "cause",
      render: (data: IPenalty) => {
        return <p>{data.processing_body || "-"}</p>;
      },
      width: 200,
      sorter: (a, b) => {
        const value1 = a.processing_body || "";
        const value2 = b.processing_body || "";
        return value1.localeCompare(value2);
      },
    },
  ];
  return (
    <Card className="table-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          type="primary"
          icon={<FilterOutlined />}
          onClick={props.onFilterClick}
        >
          Filtri
        </Button>

        <FiltersRow
          filters={props.filters}
          onFilterRemove={handleFilterRemove}
        />
      </div>

      <Table
        loading={props.loading}
        dataSource={props.data}
        columns={columns}
        scroll={{ x: "max-content", y: "50vh" }}
      />
      <Button
        key="submit"
        type="primary"
        icon={<DownloadOutlined />}
        onClick={props.download}
      >
        Scarica XLSX
      </Button>
    </Card>
  );
};

export default SubmittedPenaltiesTable;
