import { useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Input, Select } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { filterObject } from "../common/Utils";
import { PenaltyFilters } from "../common/interfaces";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const FiltersDrawer = (props: {
  open: boolean;
  onClose: any;
  onFilterConfirm: any;
  availableFilters: string[];
  filters: PenaltyFilters;
}): JSX.Element => {
  const [lineCode, setLineCode] = useState("");
  const [status, setStatus] = useState("");

  const [uploadDateStart, setUploadDateStart] = useState(null);
  const [uploadDateEnd, setUploadDateEnd] = useState(null);

  const [orderDateStart, setOrderDateStart] = useState(null);
  const [orderDateEnd, setOrderDateEnd] = useState(null);

  const [expirationDateStart, setExpirationDateStart] = useState(null);
  const [expirationDateEnd, setExpirationDateEnd] = useState(null);

  useEffect(() => {
    setLineCode(props.filters.line_code || "");
    setStatus(props.filters.status || "");

    const uploadStart = props.filters.received_on?.start;
    const uploadEnd = props.filters.received_on?.end;

    const orderStart = props.filters.order_date?.start;
    const orderEnd = props.filters.order_date?.end;

    const expirationStart = props.filters.expiration_date?.start;
    const expirationEnd = props.filters.expiration_date?.end;

    setUploadDateStart(uploadStart ? (dayjs(uploadStart) as any) : null);
    setUploadDateEnd(uploadEnd ? (dayjs(uploadEnd) as any) : null);

    setOrderDateStart(orderStart ? (dayjs(orderStart) as any) : null);
    setOrderDateEnd(orderEnd ? (dayjs(orderEnd) as any) : null);

    setExpirationDateStart(
      expirationStart ? (dayjs(expirationStart) as any) : null
    );
    setExpirationDateEnd(expirationEnd ? (dayjs(expirationEnd) as any) : null);
  }, [props.filters]);

  const handleLineCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLineCode(e.target.value);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const resetFilters = () => {
    setStatus("");
    setLineCode("");
    setUploadDateStart(null);
    setUploadDateEnd(null);

    setOrderDateStart(null);
    setOrderDateEnd(null);

    setExpirationDateStart(null);
    setExpirationDateEnd(null);
  };

  const handleOrderDateChange = (dates: any) => {
    setOrderDateStart(dates ? (dates[0] as any) : null);
    setOrderDateEnd(dates ? (dates[1] as any) : null);
  };

  const handleUploadDateChange = (dates: any) => {
    setUploadDateStart(dates ? (dates[0] as any) : null);
    setUploadDateEnd(dates ? (dates[1] as any) : null);
  };

  const handleExpirationDateChange = (dates: any) => {
    setExpirationDateStart(dates ? (dates[0] as any) : null);
    setExpirationDateEnd(dates ? (dates[1] as any) : null);
  };

  function dayJSToDate(originalDate?: any) {
    if (originalDate) {
      const date = originalDate?.toDate();

      return date?.toISOString() as any;
    }
    return null;
  }

  function confirmFilters() {
    const payload = {
      status: status,
      line_code: lineCode,
      order_date: orderDateStart
        ? {
            start: dayJSToDate(orderDateStart),
            end: dayJSToDate(orderDateEnd),
          }
        : null,
      received_on: uploadDateStart
        ? {
            start: dayJSToDate(uploadDateStart),
            end: dayJSToDate(uploadDateEnd),
          }
        : null,
      expiration_date: expirationDateStart
        ? {
            start: dayJSToDate(expirationDateStart),
            end: dayJSToDate(expirationDateEnd),
          }
        : null,
    };

    props.onFilterConfirm(filterObject(payload));
    resetFilters();
  }

  return (
    <Drawer title="Filtri" onClose={() => props.onClose()} open={props.open}>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {props.availableFilters.includes("line_code") ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Linea</label>
            <Input
              placeholder="NAZ123"
              onChange={handleLineCodeChange}
              value={lineCode}
            />
          </div>
        ) : null}

        {props.availableFilters.includes("status") ? (
          <div>
            <label>Stato</label>
            <Select id="status" onChange={handleStatusChange} value={status}>
              <Select.Option value="APPLIED">Penale Applicata</Select.Option>
              <Select.Option value="NOT_APPLIED">
                Penale non applicata
              </Select.Option>
              <Select.Option value="TO_REVIEW">
                Giustificazione Inviata
              </Select.Option>
              <Select.Option value="BILLED">Fatturata</Select.Option>
            </Select>
          </div>
        ) : null}

        {props.availableFilters.includes("upload_date") ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Data caricamento</label>
            <RangePicker
              onChange={handleUploadDateChange}
              value={[uploadDateStart, uploadDateEnd]}
            />
          </div>
        ) : null}

        {props.availableFilters.includes("order_date") ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Data ordine</label>
            <RangePicker
              onChange={handleOrderDateChange}
              value={[orderDateStart, orderDateEnd]}
            />
          </div>
        ) : null}

        {props.availableFilters.includes("expiration_date") ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Data di scadenza</label>
            <RangePicker
              onChange={handleExpirationDateChange}
              value={[expirationDateStart, expirationDateEnd]}
            />
          </div>
        ) : null}
        <i>L'applicazione dei filtri svuoterà i campi compilati</i>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={confirmFilters}
          >
            Conferma
          </Button>

          <Button icon={<DeleteOutlined />} onClick={resetFilters}>
            Reset
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersDrawer;
