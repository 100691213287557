import axios from "axios";
import { IPenalty } from "./penalty.model";
import Cookies from "js-cookie";
import { PenaltyFilters } from "../../common/interfaces";

export const getSubmitted = async (filters?: PenaltyFilters) => {
  const defaultStatus = ["NOT_APPLIED", "APPLIED", "BILLED", "TO_REVIEW"];

  const status = filters?.status || defaultStatus.join(",");
  const params = {
    ...filters,
    status,
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/penalties`, {
    params,
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const getPending = async (filters?: PenaltyFilters) => {
  const defaultStatus = ["PENDING"];

  const status = filters?.status || defaultStatus.join(",");
  const params = {
    ...filters,
    status,
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/penalties`, {
    params,
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const updatePenalties = async (penalties: IPenalty[]) => {
  const body = { penalties };
  return axios.put(`${process.env.REACT_APP_API_URL}/penalties/`, body, {
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const updatePenalty = async (
  id: string,
  penalty: IPenalty,
  attachments: any
) => {
  const formData = new FormData();

  attachments.forEach((attachment: any) => {
    const file = attachment.originFileObj;
    if (file instanceof File) {
      formData.append("attachment", file);
    }
  });

  formData.append("penalty", JSON.stringify(penalty));

  return axios.put(
    `${process.env.REACT_APP_API_URL}/penalties/${id}`,
    formData,
    {
      headers: {
        authorization: Cookies.get("_auth"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const uploadAttachments = async (id: any, attachments: any) => {
  const formData = new FormData();

  for (let attachment of attachments) {
    const file = attachment.originFileObj;
    if (file instanceof File) {
      formData.append("attachment", file);
    }
  }

  return axios.post(
    `${process.env.REACT_APP_API_URL}/penalties/upload-attachments/${id}`,
    formData,
    {
      headers: {
        authorization: Cookies.get("_auth"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
