export interface PenaltyFilters {
  status?: string;
  line_code?: string;
  received_on?: { start: Date; end: Date };
  order_date?: { start: Date; end: Date };
  expiration_date?: { start: Date; end: Date };
}

export enum PenaltyFilterLabel {
  status = "Stato",
  line_code = "Codice linea",
  received_on = "Data caricamento",
  order_date = "Data ordine",
  expiration_date = "Data scadenza",
}
