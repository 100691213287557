import React from "react";
import { Input, Tooltip } from "antd";
import { IPenalty } from "../../services/penalty";
const { TextArea } = Input;

const JusitificationCell = (props: {
  data: IPenalty;
  onChange: (value: string) => void;
}): JSX.Element => {
  function handleInputChange(event: any) {
    props.onChange(event.target.value);
  }

  return (
    <>
      <Tooltip
        placement="top"
        title={
          (props.data.justification?.body?.length || 0) +
          "/200 caratteri utilizzati"
        }
      >
        <TextArea
          rows={4}
          placeholder="Max 200 caratteri"
          onChange={handleInputChange}
          maxLength={200}
        />
      </Tooltip>
    </>
  );
};

export default JusitificationCell;
