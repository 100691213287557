import React, {useEffect, useState} from "react";
import {
  BarsOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  ExclamationOutlined,
  InfoCircleOutlined,
  SafetyOutlined,
  UserOutlined
} from "@ant-design/icons";
import type {MenuProps} from "antd";
import {Menu} from "antd";
import {useNavigate} from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Fornitore", "1", <UserOutlined />),
  getItem("Penali Servizio", "sub1", <BarsOutlined />, [
    getItem("Da giustificare", "2", <ClockCircleOutlined />),
    getItem("Inviate", "3", <CheckOutlined />),
  ]),

  getItem("Penali Sicurezza", "sub2", <SafetyOutlined />, [
    getItem("Da giustificare", "4", <ClockCircleOutlined />),
    getItem("Inviate", "5", <CheckOutlined />),
  ]),

  getItem("Info e manuale", "6", <InfoCircleOutlined />),
  getItem("SSL - Sicurezza sul lavoro", "7", <ExclamationOutlined />),
];

function mapKeyToPath(keyOrPath: any) {
  const mapping: any = {
    "1": { type: "internal", url: "/supplier" },
    "2": { type: "internal", url: "/service/pending" },
    "3": { type: "internal", url: "/service/submitted" },
    "4": { type: "internal", url: "/safety/pending" },
    "5": { type: "internal", url: "/safety/submitted" },
    "6": { type: "internal", url: "/info" },
    "7": {
      type: "external",
      url: "https://www.sennder.com/ssl",
    },
  };

  if (mapping[keyOrPath]) return mapping[keyOrPath];

  const reverseMapping = Object.keys(mapping).find(
    (key) => mapping[key].url === keyOrPath,
  );

  if (reverseMapping) return reverseMapping;

  return null;
}

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState<string>();

  useEffect(() => {
    setSelectedKey(mapKeyToPath(window.location.pathname));
  }, []);

  const handleMenuClick = ({ key }: { key: string }) => {
    let selected = mapKeyToPath(key);

    if (selected.type === "internal") {
      // Update the selected key only for internal links
      setSelectedKey(key);
      navigate(selected.url);
    } else if (selected.type === "external") {
      // Navigate to external link without updating the selected key
      window.open(selected.url);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        borderRightColor: "black",
        borderRightWidth: "1px",
      }}
    >
      <Menu
        selectedKeys={[selectedKey as string]} // Control the selected keys using state
        defaultOpenKeys={["sub1", "sub2"]}
        mode="inline"
        items={items}
        onSelect={handleMenuClick}
      />
    </div>
  );
};

export default Sidebar;
