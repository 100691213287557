import { Menu } from "antd";
import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const AppMenu = () => {
  const SignOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    SignOut();
    navigate("/login");
  };
  return (
    <Menu
      className="custom-menu"
      style={{ backgroundColor: "transparent", color: "white" }}
      mode="horizontal"
      triggerSubMenuAction={"click"}
    >
      <Menu.SubMenu
        key="expand"
        icon={<MenuOutlined />}
        style={{ backgroundColor: "transparent", color: "white" }}
      >
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined style={{ color: "red" }} />}
          style={{ color: "red" }}
          onClick={() => {
            return logout();
          }}
        >
          Logout
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default AppMenu;
