import {BrowserRouter, Route, Routes} from "react-router-dom";
import {RequireAuth} from "react-auth-kit";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import InfoPage from "./pages/InfoPage";
import SubmittedPenaltiesPage from "./pages/SubmittedPenaltiesPage";
import LoginPage from "./pages/LoginPage";
import AppLayout from "./layouts/AppLayout";
import RecoverPasswordPage from "./pages/RecoverPasswordPage";
import SafetyPenaltiesPage from "./pages/SafetyPenaltiesPage";
import CarrierPage from "./pages/CarrierPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <HomePage />
              </AppLayout>
            </RequireAuth>
          }
        ></Route>

        <Route
          path="/service/pending"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <HomePage />
              </AppLayout>
            </RequireAuth>
          }
        />

        <Route
          path="/info"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <InfoPage />
              </AppLayout>
            </RequireAuth>
          }
        />

          <Route
              path="/supplier"
              element={
                  <RequireAuth loginPath="/login">
                      <AppLayout>
                          <CarrierPage />
                      </AppLayout>
                  </RequireAuth>
              }
          />


        <Route
          path="/service/submitted"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <SubmittedPenaltiesPage />
              </AppLayout>
            </RequireAuth>
          }
        />

        <Route
          path="/safety/pending"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <SafetyPenaltiesPage />
              </AppLayout>
            </RequireAuth>
          }
        />

        <Route
          path="/safety/submitted"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <SafetyPenaltiesPage />
              </AppLayout>
            </RequireAuth>
          }
        />

        <Route
          path="/*"
          element={
            <RequireAuth loginPath="/login">
              <AppLayout>
                <NotFoundPage />
              </AppLayout>
            </RequireAuth>
          }
        />

        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/login/recover-password"
          element={<RecoverPasswordPage />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
