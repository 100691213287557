import React from "react";
import { Select } from "antd";
import { IPenalty, PenaltyJustificationCategory } from "../../services/penalty";

const CategoryCell = (props: {
  data: IPenalty;
  onChange: (value: string) => void;
}): JSX.Element => {
  function handleSelectChange(value: any) {
    props.onChange(value);
  }

  const options = [
    {
      value: "EMPTY_VEHICLE",
      label: PenaltyJustificationCategory.EMPTY_VEHICLE,
    },
    {
      value: "WRONG_DOCUMENT",
      label: PenaltyJustificationCategory.WRONG_DOCUMENT,
    },
    {
      value: "BROKEN_VEHICLE",
      label: PenaltyJustificationCategory.BROKEN_VEHICLE,
    },
    {
      value: "TRAVEL_TIME",
      label: PenaltyJustificationCategory.TRAVEL_TIME,
    },
    {
      value: "HUB_DELAY",
      label: PenaltyJustificationCategory.HUB_DELAY,
    },
    {
      value: "TRAFFIC",
      label: PenaltyJustificationCategory.TRAFFIC,
    },
    { value: "FERRY_DELAY", label: PenaltyJustificationCategory.FERRY_DELAY },
    {
      value: "VEHICLE_ACCIDENT",
      label: PenaltyJustificationCategory.VEHICLE_ACCIDENT,
    },
    { value: "POLICE_STOP", label: PenaltyJustificationCategory.POLICE_STOP },
    {
      value: "HUB_OPENING",
      label: PenaltyJustificationCategory.HUB_OPENING,
    },
    {
      value: "OTHER",
      label: PenaltyJustificationCategory.OTHER,
    },
  ];

  return (
    <>
      <Select
        style={{ width: "100%" }}
        showSearch
        placeholder="Seleziona una categoria"
        optionFilterProp="items"
        onChange={handleSelectChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={options}
      />
    </>
  );
};

export default CategoryCell;
