import { Modal } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { AttachmentCell } from "../PenaltiesTable";
import { IPenalty } from "../../services/penalty";
import RequiredDocuments from "../RequiredDocuments";

const AttachmentsModal = (props: {
  onOk: any;
  onCancel: any;
  open: any;
  data: IPenalty;
  onFileUpload: any;
  onFileRemove: any;
}) => {
  const ModalTitle = (
    <>
      <FileAddOutlined style={{ marginRight: "8px", color: "green" }} />
      Carica Allegati - {props?.data?.sennder_reference}(
      {props?.data?.tour_composition})
    </>
  );
  const justificationCategory = props.data?.justification?.category || null;
  return (
    <Modal
      title={ModalTitle}
      onOk={props.onOk}
      onCancel={props.onCancel}
      open={props.open}
    >
      <>
        <AttachmentCell
          data={props.data}
          onUpload={(value: string) =>
            props.onFileUpload(props.data._id, value)
          }
          onRemove={(value: string) =>
            props.onFileRemove(props.data._id, value)
          }
        />
        <RequiredDocuments category={justificationCategory} />
      </>
    </Modal>
  );
};

export default AttachmentsModal;
