import { Card, Col, Row } from "antd";
import "../assets/css/stats.css";
import "../assets/css/card.css";

interface IProps {
    isLoading: boolean;
    data?: any;
    onTilesClick: any;
}

const RecapTiles = (props: IProps) => {
    const { data} = props;
    return (
        <div className="stats">
            <Row gutter={16} className={"mb-3"}>
                <Col span={5}>
                    <Card title="Fornitore" size="small" style={{ borderRadius: "10px" }}>
                        <h3>{data?.carrier}</h3>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card title="Totale Penali" size="small" style={{ borderRadius: "10px" }} onClick={() => props.onTilesClick('')} className={"clickable-card"}>
                        <h3>{data?.penalties.total}</h3>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card title="Giustificazioni Inviate" size="small" style={{ borderRadius: "10px" }} onClick={() => props.onTilesClick('TO_REVIEW')} className={"clickable-card"}>
                        <h3>{data?.penalties.to_review}</h3>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card title="Penali Applicate" size="small" style={{ borderRadius: "10px" }} onClick={() => props.onTilesClick('APPLIED')} className={"clickable-card"}>
                        <h3>{data?.penalties.applied}</h3>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card title="Penali Non applicate" size="small" style={{ borderRadius: "10px" }} onClick={() => props.onTilesClick('NOT_APPLIED')} className={"clickable-card"}>
                        <h3>{data?.penalties.not_applied}</h3>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default RecapTiles;
